import { FC, lazy } from 'react';

const LessonListing = lazy(() => import('../components/Lesson/LessonListing'));
const LessonFrom = lazy(() => import('../components/Lesson/LessonFrom'));
const ChapterForm = lazy(() => import('../components/Chapter/ChapterForm'));
const ChapterView = lazy(() => import('../components/Chapter/ChapterView'));
const Login = lazy(() => import('../components/Auth/Login'));
const Forget = lazy(() => import('../components/Auth/Forget'));

const QuestionForm = lazy(
  () => import('../components/QuestionBank/QuestionForm'),
);
const QuestionWrapper = lazy(
  () => import('../components/QuestionBank/QuestionWrapper'),
);
const DinarWrapper = lazy(() => import('../components/DinarPack/DinarWrapper'));
const GeneralSettings = lazy(
  () => import('../components/GeneralSettings/GeneralSettings'),
);
const LessonOrdering = lazy(
  () => import('../components/LessonOrdering/LessonOrdering'),
);
const Notification = lazy(
  () => import('../components/Notification/Notification'),
);
const LessonViewWrapper = lazy(
  () => import('../components/LessonView/LessonViewWrapper'),
);
const StudentProgress = lazy(
  () => import('../components/StudentProgress/StudentProgress'),
);
const CodeWrapper = lazy(
  () => import('../components/CodeGeneration/CodeWrapper'),
);
const AllChapters = lazy(() => import('../components/ChapterView/AllChapters'));
const Reports = lazy(() => import('../components/Reports/Reports'));
const Students = lazy(() => import('../components/Students'));
const UserManagement = lazy(() => import('../components/User/UserManagement'));
const UserSubscription = lazy(
  () => import('../components/User/UserSubscription'),
);

export interface RoutesModel {
  path: string;
  isIncludeInSideBar: boolean;
  heading?: string;
  sideBarHeading?: string;
  isProtected: boolean;
  Component: FC;
  access: Array<string>;
  icon?: string;
  isHeadingShow?: boolean;
}

export const allRoutes: RoutesModel[] = [
  {
    path: '/',
    isIncludeInSideBar: false,
    isProtected: false,
    Component: Login,
    access: ['superAdmin', 'admin', 'teacher'],
  },

  {
    path: '/login',
    isIncludeInSideBar: false,
    isProtected: false,
    Component: Login,
    access: ['superAdmin', 'admin', 'teacher'],
  },

  {
    path: '/forget',
    isIncludeInSideBar: false,
    isProtected: false,
    Component: Forget,
    access: ['superAdmin', 'admin', 'teacher'],
  },

  {
    path: '/lessons',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'Lessons',
    icon: 'fa-solid fa-book-open-reader',
    Component: LessonListing,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Lessons',
    icon: 'fa-solid fa-book-open-reader',
    Component: LessonListing,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/lesson',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Add lesson',
    Component: LessonFrom,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/lesson/:gradeID/:subjectID/:chapterID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Add lesson',
    Component: LessonFrom,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/lesson/edit/:lessonID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Update lesson',
    Component: LessonFrom,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/chapter/:gradeID/:subjectID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Add Chapter',
    Component: ChapterForm,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/chapter/edit/:gradeID/:subjectID/:chapterID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Update Chapter',
    Component: ChapterForm,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/chapter/:chapterID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Chapter',
    Component: ChapterView,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/questions',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'Questions Bank',
    Component: QuestionWrapper,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Question Bank',
    icon: 'fa-regular fa-comment-dots',
  },
  {
    path: '/question/multiple-choice',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Add Question',
    Component: QuestionForm,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Question Bank',
    icon: 'fa-regular fa-comment-dots',
  },
  {
    path: '/question/multiple-choice/:questionID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Edit Question',
    Component: QuestionForm,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Question Bank',
    icon: 'fa-regular fa-comment-dots',
  },
  {
    path: '/question/multiple-choice/:questionID/lesson/:lessonID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Edit Question',
    Component: QuestionForm,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Question Bank',
    icon: 'fa-regular fa-comment-dots',
  },
  {
    path: '/lesson/view/:lessonID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Lesson View',
    Component: LessonViewWrapper,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/chapters/:subjectID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Chapter View',
    Component: AllChapters,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
    isHeadingShow: false,
  },
  {
    path: '/subject/view/:subjectID/student-progress',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Student Progress',
    Component: StudentProgress,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/dinar',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'Dinar Packs',
    Component: DinarWrapper,
    access: ['superAdmin', 'admin'],
    sideBarHeading: 'Dinar Packs',
    isHeadingShow: true,
    icon: 'fa-solid fa-coins',
  },
  {
    path: '/codes',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'Codes Generation',
    Component: CodeWrapper,
    access: ['superAdmin', 'admin'],
    sideBarHeading: 'Codes Generation',
    isHeadingShow: true,
    icon: 'fa-solid fa-tachograph-digital',
  },
  {
    path: '/lesson/ordering/:chapterID',
    isIncludeInSideBar: false,
    isProtected: true,
    heading: 'Lesson ordering',
    isHeadingShow: true,
    Component: LessonOrdering,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Lessons',
  },
  {
    path: '/notifications',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'Sending Notification',
    isHeadingShow: true,
    Component: Notification,
    access: ['superAdmin', 'admin'],
    sideBarHeading: 'Notifications',
    icon: 'fa-solid fa-bell',
  },
  {
    path: '/general/setting',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'General Settings',
    isHeadingShow: true,
    Component: GeneralSettings,
    access: ['superAdmin', 'admin'],
    sideBarHeading: 'General Setting',
    icon: 'fa-solid fa-cog',
  },
  {
    path: '/reporting',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'Reporting',
    isHeadingShow: true,
    Component: Reports,
    access: ['superAdmin', 'admin'],
    sideBarHeading: 'Reports',
    icon: 'fa-solid fa-chart-bar',
  },
  {
    path: '/students',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'Students',
    isHeadingShow: true,
    Component: Students,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'Students',
    icon: 'fa-solid fa-user-graduate',
  },
  {
    path: '/user/info',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'User Delete',
    isHeadingShow: true,
    Component: UserManagement,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'User Management',
    icon: 'fa-solid fa-user',
  },
  {
    path: '/user/subscription',
    isIncludeInSideBar: true,
    isProtected: true,
    heading: 'User Subscription',
    isHeadingShow: true,
    Component: UserSubscription,
    access: ['superAdmin', 'admin', 'teacher'],
    sideBarHeading: 'User Subscription',
    icon: 'fa-solid fa-dollar-sign',
  },
];
